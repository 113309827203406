/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'default',
    custom: {
      ic_tune: {
        viewbox: '0 0 24 24',
        content: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.6097H14V16.6097H10V18.6097ZM3 6.60974V8.60974H21V6.60974H3ZM6 13.6097H18V11.6097H6V13.6097Z" fill="currentColor"/></svg>'
      },
      ic_share: {
        viewbox: '0 0 24 24',
        content: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><g id="Share Web"><path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M16.04 17.4597C16.56 16.9897 17.24 16.6897 18 16.6897C19.61 16.6897 20.92 17.9997 20.92 19.6097C20.92 21.2197 19.61 22.5297 18 22.5297C16.39 22.5297 15.08 21.2197 15.08 19.6097C15.08 19.3897 15.11 19.1697 15.16 18.9597L8.04 14.7997C7.5 15.2997 6.79 15.6097 6 15.6097C4.34 15.6097 3 14.2697 3 12.6097C3 10.9497 4.34 9.60974 6 9.60974C6.79 9.60974 7.5 9.91974 8.04 10.4197L15.09 6.30974C15.04 6.07974 15 5.84974 15 5.60974C15 3.94974 16.34 2.60974 18 2.60974C19.66 2.60974 21 3.94974 21 5.60974C21 7.26974 19.66 8.60974 18 8.60974C17.21 8.60974 16.5 8.29974 15.96 7.79974L8.91 11.9097C8.96 12.1397 9 12.3697 9 12.6097C9 12.8497 8.96 13.0797 8.91 13.3097L16.04 17.4597ZM19 5.60974C19 5.05974 18.55 4.60974 18 4.60974C17.45 4.60974 17 5.05974 17 5.60974C17 6.15974 17.45 6.60974 18 6.60974C18.55 6.60974 19 6.15974 19 5.60974ZM6 13.6097C5.45 13.6097 5 13.1597 5 12.6097C5 12.0597 5.45 11.6097 6 11.6097C6.55 11.6097 7 12.0597 7 12.6097C7 13.1597 6.55 13.6097 6 13.6097ZM17 19.6297C17 20.1797 17.45 20.6297 18 20.6297C18.55 20.6297 19 20.1797 19 19.6297C19 19.0797 18.55 18.6297 18 18.6297C17.45 18.6297 17 19.0797 17 19.6297Z" fill="currentColor"/></g></svg>'
      }
    }
  }
}
